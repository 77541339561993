import * as React from "react";
import { isDev } from "../../../common/helpers/utils";
import { useApi } from "../../../common/hooks/useApi";
import NewsCard from "./news-card";

export type News = {
  title?: string;
  description?: string;
  url?: string;
  urlToImage?: string;
  publishedAt?: string;
  author?: string;
  content?: string;
  source: {
    id: null | string;
    name: string;
  };
};
type Props = {
  keyword: string;
  pageSize?: number;
};
const baseApi = isDev ? "http://localhost:8888" : "https://einfachcrypto.de";

const NewsApiList: React.FC<Props> = ({ keyword, pageSize }) => {
  const mapper = (res: any) => res.articles;
  const url = `${baseApi}/api/get-news?keyword=${encodeURIComponent(keyword)}&pageSize=${pageSize}`;
  const { data, error, loading } = useApi<News[]>(url, mapper);

  const links = (data || []).map((item) => ({
    href: item.url,
    title: <NewsCard member={item} />,
    external: true,
  }));

  if (error) {
    return <p>An error occured...</p>;
  }
  if (loading) {
    return <p>loading...</p>;
  }

  return <div className="grid grid-cols-3 gap-5">{links.map((link) => link.title)}</div>;
};
export default NewsApiList;
