import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import Layout from "../../components/layout";
import CoinList from "../../components/Modules/dashboard/coin-table";
import NewsApiList from "../../components/Modules/news/newsapi";
import SEO from "../../components/seo";
import Card from "../../components/UI-Elements/Card";

// https://coinmarketcap.com/nft/upcoming/
const title = 'Was sind NFTs?';
const topic = 'NFTs';

const NftIndex = () => {
  return (
    <Layout>
      <SEO keywords={['nft', 'non-fungible token']} title={title} robots="noindex" />
      <Title>NFT (Non-fungible token)</Title>
      <div className="grid gap-4">
        <section className="py-4">
          <div className="grid gap-4">
            <Card title={title}>
              <p className="pb-2">
                Theoretisch kann jeder Vermögenswert digitalisiert und zum NFT werden: Zeichnungen, digitale Kunstwerke, Videoclips oder
                auch echte Besitztümer. Wichtig ist dabei, dass die NFTs Informationen beinhalten, die ihre Einzigartigkeit belegen. So
                lässt sich der jeweilige Besitzer stets zurückverfolgen und kann seinen Anspruch geltend machen. Nun stellt sich natürlich
                die Frage, wie man einen digitalen Vermögenswert schützt? Schließlich kann man eine Kopie eines jeden digitalen Kunstwerks
                durch einen einfachen Rechtsklick mit der Maus auf seinem eigenen PC speichern.
              </p>
            </Card>
          </div>
        </section>
      </div>
      <section className="py-4">
        <Title level={2}>5 {topic} Kryptowährungen</Title>
        <CoinList coins={["ethereum", "theta-token", "decentraland", "enjincoin", "chiliz"]} />
      </section>
      <section>
        <iframe
          width="100%"
          height="420"
          frameBorder="0"
          src="https://embed.theblockcrypto.com/data/nft-non-fungible-tokens/gaming/gaming-nft-trade-volume/embed"
          title="Gaming NFT Trade Volume"
        ></iframe>
      </section>
      <section className="py-4">
        <Title level={2}>Aktuelle News zu NFTs?</Title>
        <NewsApiList keyword={'nft'} pageSize={6} />
      </section>
    </Layout>
  );
};

export default NftIndex;
